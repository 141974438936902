import { IDevelopmentalStage } from './developmentalStage';

export interface IFacility {
  id: number;
  name: string;
  location: Location;
  type: FacilityType;
  medications: any[];
  treatments: any[];
  layersCount: number;
  facilityStages: BatchElement[];
  children?: IFacility[];
}

export interface IMedication {
  id: number;
  name: string;
  procedure: string;
  dose: number;
  remarks: string;
  dateApplied: string;
  unit: string | null;
}

export interface ITreatment {
  id: number;
  name: string;
  procedure: string;
  dose: number;
  remarks: string;
  dateApplied: string;
  unit: string | null;
}

export interface BatchElement {
  id: number;
  type: FacilityType;
  assignedWaterbody: any;
  createdAt: string;
  inspectorId: any[];
  batch: BatchBatch;
  inventory: any;
  isCurrent: boolean;
  remarks: string;
  layers: number[];
  number: number;
  weight: { weight: number; number: number };
  weightHistory: { id: number; value: { number: number; weight: number; date: string } }[];
  medications: IMedication[];
  treatments: ITreatment[];
  developmentalStage: IDevelopmentalStage | null;
}

export interface BatchBatch {
  id: number;
  waterbody: any;
  fishtype: any;
  number: number;
  year: number;
  developmentalStage: IDevelopmentalStage;
  breedingLocation: Location;
  mother: null;
  father: null;
  remarks: Array<any>;
}

export interface Location {
  id: number;
  name: string;
  district: string;
}

export enum FacilityType {
  Hatchery = 'hatchery',
  RoundPool = 'roundpool',
  LongStreamBasin = 'long_stream_basin',
  Stream = 'stream',
  Pond = 'pond',
}
