interface IWhiteList {
  name: string;
  code: string;
  apiDomain: string;
  version: number;
}

export const configVersion = 6;

export const webshopRegex = /https:\/\/.*\.ur\.ch/;
export const controllAppScanVersion = 4;
export const scanAccountIdentifier = 'graubunden';

export const cantonWhitelist = [
  {
    name: 'Fischerei-App Graubünden',
    code: 'gr',
    apiDomain: 'gr-api.fishven.com',
    version: configVersion,
  },
] as IWhiteList[];
